document.addEventListener("DOMContentLoaded", () => {
    // Check if the cookies are already set
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    // Check if both cookies are already set
    const marketingAndAnalytics = getCookie("marketing_and_analytics");
    const preferences = getCookie("preferences");

    if (!marketingAndAnalytics || !preferences) {
        // Show the modal if cookies are not set
        const modal = new bootstrap.Modal(document.getElementById("changePreferencesModal"), {});
        modal.show();

        $('#changePreferencesModal').modal({
            backdrop: 'static',
            keyboard: false
        })
    }

    // Save Cookie Settings
    const saveCookieSettings = document.querySelector("#saveCookieSettings");
    if (saveCookieSettings) {
        const setTheCookies = (type = "aggree") => {
            let analytics = true;
            let preferences = true;

            if (type === "custom") {
                analytics = document.querySelector("#analyticsInput").checked;
                preferences = document.querySelector("#preferencesInput").checked;
            }

            // Set the cookies
            const dateOneYearAfter = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            document.cookie = `marketing_and_analytics=${analytics};path=/;secure;Expires=${dateOneYearAfter.toUTCString()}`;
            document.cookie = `preferences=${preferences};path=/;secure;Expires=${dateOneYearAfter.toUTCString()}`;

            // Remove the cookie modal
            document.querySelector("#changePreferencesModal .btn-close").click();
            document.querySelectorAll(".cookieBar").forEach((item) => item.remove());
        };

        // Agree all cookies
        document.querySelectorAll(".acceptcookies").forEach((item) => {
            item.addEventListener("click", setTheCookies.bind(this, "aggree"));
        });

        // Save custom cookie settings
        saveCookieSettings.addEventListener("click", () => setTheCookies("custom"));
    }
});


